@import 'mixins'; // Mixins
@import 'variables'; // CSS variables
@import 'palette'; // Color palette
@import 'reset'; // CSS resets

@import "rafa-global-app-theme.scss"; // RAFA Theme

/* Bliss Pro fonts */
@font-face {
  font-family: "Bliss Pro";
  src: url('/assets/fonts/bliss-pro/blsprw-xlt.woff2');
  font-weight: 100;
}

@font-face {
  font-family: "Bliss Pro";
  src: url('/assets/fonts/bliss-pro/blsprw-lt.woff2');
  font-weight: 300;
}

@font-face {
  font-family: "Bliss Pro";
  src: url('/assets/fonts/bliss-pro/blsprw-rg.woff2');
  font-weight: 400;
}

@font-face {
  font-family: "Bliss Pro";
  src: url('/assets/fonts/bliss-pro/blsprw-md.woff2');
  font-weight: 500;
}

@font-face {
  font-family: "Bliss Pro";
  src: url('/assets/fonts/bliss-pro/blsprw-bd.woff2');
  font-weight: 700;
}

/* General Styles */
html,
body {
  font-family: Roboto, Arial;
  font-size: 62.5%;
  margin: 0;
  height: 100%;
}

/* Add login/logout background color to body to prevent background flashing on load */
body:has(login .login-container),
body:has(logout .logout-container) {
  background-color: #FFFFFF;
}

.main-content-container {
  font-size: 1.4rem;
  line-height: 2.0rem;
  color: color($gray-palette, 900);

  @include md {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  @include sm {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

a {
  color: color($primary-palette, 700);
}

.toast-container .ngx-toastr {
  font-size: 1.4rem;
}

/* Scrollbars
::-webkit-scrollbar {
  width: 1.4rem;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: 0.3rem solid $white;
  background-clip: padding-box;
  border-radius: 0.8rem;
  outline: none;
}

::-webkit-scrollbar-track {
  background-color: color($gray-palette, 25);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(color($gray-palette, 200), 0.5);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
*/


/* Legacy Styles to phase out/refactor */

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.horizontal-center {
  margin: 0 auto;
}

.zero-margin {
  margin-bottom: 0 !important;
}

.flex-div {
  display: flex;
  flex-wrap: wrap;
}

.full-width {
  width: 100%;
}

.bold {
  font-weight: 500;
}

.disabled-anchor {
  pointer-events: none !important;
  cursor: default;
}

/* For dropdown visibility */
div.cdk-overlay-container {
  z-index: 1500;
}

::ng-deep .mat-card {
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2) !important;
}

.margin-right10 {
  margin-right: 10px !important;
}

.margin-top10 {
  margin-top: 10px;
}

.loading-container {
  background: none repeat scroll 0 0 black;
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 101;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: white;
}

.loading-overlay {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto
}

.blocker-ui-container {
  position: fixed;
  width: 100%;
  height: 100%;
  /* z-index: 1001; */
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
}

mat-spinner,
.mat-spinner {
  opacity: 0.4;
  width: 48px;
  height: 48px;
}