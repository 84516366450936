// 1488 px wide max width
@mixin xxl {
  @media (max-width: 1488px) {
    @content;
  }
}

// 1064 px wide max width
@mixin xl {
  @media (max-width: 1064px) {
    @content;
  }
}

// 920 px wide max width
@mixin lg {
  @media (max-width: 920px) {
    @content;
  }
}

// 700 px wide max width
@mixin md {
  @media (max-width: 700px) {
    @content;
  }
}

// 400 px wide max width
@mixin sm {
  @media (max-width: 400px) {
    @content;
  }
}
