/* PRIMARY COLORS */
/* Base */
$white: #ffffff;
$black: #000000;

/* Neutral Gray */
$gray-palette: (
  10: #F8F8F9,
  25: #EEEEEE,
  50: #E0E0E0 ,
  100: #CCCCCC,
  200: #BDBDBD,
  300: #A3A3A3,
  400: #8F8F8F,
  500: #757575,
  600: #666666,
  700: #424242,
  800: #3D3D3D,
  900: #212121
);

/* Primary */
$primary-palette: (
  10: #E8F2FE,
  25: #E8F2FE,
  50: #D1E6FE,
  100: #AFD4FD,
  200: #87BEFD,
  300: #5FA8FC,
  400: #3792FB,
  500: #0F7DFA,
  600: #0469DC,
  700: #005EFF,
  800: #0028A0,
  900: #001966
);

/* Accent */
$accent-palette: (
  25: #D6F3FF,
  50: #ADE7FF,
  100: #85DAFF,
  200: #5CCEFF,
  300: #33C2FF,
  400: #0AB6FF,
  500: #0091CF,
  600: #0469DC,
  700: #00648F,
  800: #004766,
  900: #002B3D
);

/* Success */
$success-palette: (
  25: #EFF7F5,
  50: #E0EEEB,
  100: #C1DED7,
  200: #A2CDC2,
  300: #83BDAE,
  400: #64AC9A,
  500: #559283,
  600: #46786C,
  700: #375F55,
  800: #28453E,
  900: #192B27
);

/* Error Red */
$error-palette: (
  25: #F9F1F0,
  50: #F3E2E1,
  100: #E8C9C8,
  200: #DEB0AE,
  300: #D39794,
  400: #C87D7A,
  500: #BE6460,
  600: #B34B46,
  700: #863835,
  800: #5A2623,
  900: #2D1311
);

/* Orange */
$orange-palette: (
  25: #FDF2ED,
  50: #F9DFD2,
  100: #F5CCB7,
  200: #F1B99D,
  300: #EDA582,
  400: #EA9267,
  500: #E67F4C,
  600: #C36C41,
  700: #A15935,
  800: #7F462A,
  900: #5C331E
);

/* Attention Yellow */
$yellow-palette: (
  25: #FBF1DD,
  50: #F7E3BC,
  100: #F3D69A,
  200: #EFC878,
  300: #D7B46C,
  400: #BFA060,
  500: #A78C54,
  600: #8F7848,
  700: #77643C,
  800: #605030,
  900: #483C24
);

/* SECONDARY COLORS */
/* Teal */
$teal-palette: (
  25: #F1FEFF,
  50: #D8F8FB,
  100: #BBEFF3,
  200: #9EE2E7,
  300: #81D0D7,
  400: #56BBC6,
  500: #34A3B1,
  600: #1C8798,
  700: #0C6C7E,
  800: #045263,
  900: #003B4A
);

/* Decorative Violet */
$violet-palette: (
  25: #FBFAFF,
  50: #F5F3FF,
  100: #ECE9FE,
  200: #DDD6FE,
  300: #C3B5FD,
  400: #A48AFB,
  500: #875BF7,
  600: #7839EE,
  700: #6927DA,
  800: #5720B7,
  900: #491C96
);

/* Green */
$green-palette: (
  10: #F5FCF5,
  25: #E9F6EA,
  50: #D4EDD5,
  100: #B8E0BA,
  200: #98D49E,
  300: #7EC882,
  400: #62BC66,
  500: #44A048,
  600: #039855,
  700: #317234,
  800: #255627,
  900: #18391A
);

/* Rose */
$rose-palette: (
  25: #FFF5F6,
  50: #FFF1F3,
  100: #FFE4E8,
  200: #FECDD6,
  300: #FEA3B4,
  400: #FD6F8E,
  500: #F63D68,
  600: #E31B54,
  700: #C01048,
  800: #A11043,
  900: #89123E
);

/* Branding Blue */
$branding-palette: (
  10: #F5F8FF,
  25: #EBF0FF,
  50: #DDE5FD,
  100: #CBD6FA,
  200: #B2C2F3,
  300: #93A9EA,
  400: #6E8ADD,
  500: #4668CC,
  600: #2046B8,
  700: #0028A0,
  800: #012283,
  900: #001966
);

@function color($color, $value) {
  @return map-get($color, $value);
}
